import _ from 'lodash'
import moment from 'moment-timezone'
import {
  LOAD_EVENT,
  IS_LOADING_STATE,
  LOAD_SEATING_AREAS_SUCCESS,
  LOAD_EVENTS_SUCCESS,
  LOAD_EVENT_SALES_SUCCESS,
  LOAD_EVENT_INVENTORIES_SUCCESS,
  ADD_EVENT,
  SAVE_EVENT_SUCCESS,
  DELETE_EVENT_CONFIRM,
  DELETE_EVENT_SUCCESS,
  UPDATE_EVENT,
  CLONE_EVENT,
  UPDATE_DAYS,
  CROPPING,
  CROPPING_SAVE,
  UPLOAD_PHOTO_PREVIEW,
  UPLOAD_PHOTO_SUCCESS,
  UPDATE_PHOTO_TITLE,
  CROP_PHOTO,
  DELETE_PHOTO,
  EXCLUDE_DATE_EVENT_SUCCESS,
  TOGGLE_RECURS,
  CHANGE_RECURRENCE_FREQUENCY,
  CHANGE_BOOKING_POLICY_TYPE,
  CHANGE_BOOKING_POLICY,
  CHANGE_CANCELLATION_POLICY_TYPE,
  CHANGE_CANCELLATION_POLICY,
  LOAD_VENUE_CONFIG,
  UPDATE_VALID_LINK,
  RESET_EVENT,
  UPDATE_EDITING_EVENT_START_DATE,
  LOAD_EVENT_DESCRIPTION_DATA,
  LOAD_EVENT_IMAGE_DATA,
  TOGGLE_3D_FLOORPLAN,
  TOGGLE_MULTI_INVENTORY,
} from './ActionTypes'
// eslint-disable-next-line import/no-cycle
import { addInventory, loadInventory } from './inventory'
import {
  requestEvents,
  requestEventSales,
  requestEventInventories,
  requestSeatingAreas,
  requestExcludeDate,
  uploadPhotoRequest,
  requestSaveEvent,
  requestDeleteEvent,
  requestEvent,
  loadFeatureFlags,
  getFeatureFlagsProvider,
} from './services'
import { copyToClipboard } from '@sevenrooms/core/ui-kit/utils'

export const saveEventSuccess = (id, event) => ({ type: SAVE_EVENT_SUCCESS, id, event })

export const excludeDateHandlerSuccess = (id, dt, selectedValue) => ({ type: EXCLUDE_DATE_EVENT_SUCCESS, id, dt, selectedValue })

export const loadEvent = id => (dispatch, getState) => {
  if (_.isNil(id) || id === 'new') {
    dispatch(loadEventAction(id))
    return Promise.resolve(null)
  }
  const state = getState()
  const { venueId } = state

  return Promise.all([loadEventInventories(dispatch, venueId, [id]), refreshImagesAndDescription(dispatch, venueId, id)]).then(() => {
    const currentEvent = _.find(state.eventListData.toJS(), { id })
    dispatch(loadEventAction(id, currentEvent))
  })
}

export const cancelEdit = () => (dispatch, getState) => {
  const state = getState()
  const event = state.currentEvent.original
  dispatch({ type: UPDATE_VALID_LINK, value: true })
  dispatch({ type: RESET_EVENT, event })
  dispatch(loadEventAction(null, null))
}

export const loadEventAction = (id, event) => ({ type: LOAD_EVENT, id, event })

export const cloneEvent = id => (dispatch, getState) => {
  const state = getState()
  const { venueId } = state
  return Promise.all([loadEventInventories(dispatch, venueId, [id]), refreshImagesAndDescription(dispatch, venueId, id)]).then(() => {
    dispatch(cloneEventAction(id))
  })
}

export const cloneEventAction = id => ({ type: CLONE_EVENT, id })

export const linkEvent = (id, linkUrl) => (dispatch, getState) => {
  copyToClipboard(`${linkUrl}`)
  Interface.alertSuccess('Link copied!')
  return { type: UPDATE_EVENT, id }
}

export const excludeDateHandler = (id, dt, name, selectedValue) => (dispatch, getState) => {
  const state = getState()
  const currentEvent = _.find(state.eventListData.toJS(), { id })
  if (selectedValue) {
    currentEvent.excluded_dates = _.union(currentEvent.excluded_dates, [dt])
  } else {
    currentEvent.excluded_dates = _.difference(currentEvent.excluded_dates, [dt])
  }
  return requestExcludeDate(currentEvent, state).then(eventData => {
    if (eventData.error) {
      dispatch(loadEvent(null))
    } else {
      dispatch(excludeDateHandlerSuccess(id, dt, selectedValue))
      dispatch(loadEvent(null))
    }
  })
}

export const postDeleteEvent = id => ({ type: DELETE_EVENT_SUCCESS, id })

const validateEvent = event => {
  const venue_today = document.getElementById('today_date_url').value
  const validator = new sr.Validator('#event-editor')
  const default_validation = validator.validate()
  if (!default_validation) {
    return false
  }

  const event_start_date = moment(event.start_date)
  const event_end_date = moment(event.end_date)
  const venue_today_date = moment(venue_today)
  const event_start_time = moment(event.start_time, ['h:mm A'])
  const event_end_time = moment(event.end_time, ['h:mm A'])

  if (event_end_date < venue_today_date) {
    Interface._alert("Event end date can't be in the past.")
    return false
  }
  if (event_end_date < event_start_date) {
    Interface._alert("Event end date can't be less than the start date.")
    return false
  }
  if (!event.is_recurring && event_end_time - event_start_time + event_end_date - event_start_date < 0) {
    Interface._alert("Event can't end before it starts.")
    return false
  }
  if (event.is_recurring && !_.includes(['DAILY', 'WEEKLY'], event.recurring_type)) {
    Interface._alert('Please select how frequently this event recurs.')
    return false
  }
  return true
}

export const saveEvent = (venueId, id, editorState) => (dispatch, getState) => {
  const state = getState()
  const currentEvent = _.find(state.eventListData.toJS(), { id })
  const eventListData = state.eventListData.toJS()
  const experienceEventExample = eventListData.find(
    event => event.experience_id && event.experience_id === currentEvent.experience_id && event.id !== state.currentEvent.id
  )
  const experienceEventTypeIsPrivate = experienceEventExample && experienceEventExample.private_event
  const validLink = experienceEventExample ? experienceEventTypeIsPrivate === currentEvent.private_event : true
  dispatch({ type: UPDATE_VALID_LINK, value: validLink })

  if (!validateEvent(currentEvent) || !validLink) {
    return { type: 'FAIL' }
  }

  return requestSaveEvent(currentEvent, state).then(eventData => {
    // If id is mutated, then it's possible multiple events are updated, so refetch all events
    if (eventData.error || eventData.id !== id) {
      dispatch(loadEvents(venueId))
      dispatch(loadEvent(null))
    } else {
      dispatch(loadEvents(venueId))
      dispatch(saveEventSuccess(eventData.id, eventData))
      dispatch(loadEvent(null))
    }
  })
}

// eslint-disable-next-line consistent-return
export const updateEventForInventory = (venueId, eventId) => (dispatch, getState) => {
  const state = getState()
  const currentEvent = _.find(state.eventListData.toJS(), { id: eventId })
  if (!validateEvent(currentEvent)) {
    return { type: 'FAIL' }
  }
  const onEventWithId = eventData => {
    dispatch(addInventory(eventData.id))
    dispatch(loadInventory('new'))
  }
  if (eventId === 'new') {
    return requestSaveEvent(currentEvent, state).then(eventData => {
      // For failure state, we will redirect to events page
      if (eventData.error) {
        dispatch(loadEvents(venueId))
        dispatch(loadEvent(null))
        // For success state,  reload event and then reload inventory
      } else {
        dispatch(saveEventSuccess(eventData.id, eventData))
        dispatch(loadEvent(eventData.id)).then(() => onEventWithId(eventData))
      }
    })
  }
  onEventWithId(currentEvent)
}

export const addEvent = venueId => (dispatch, getState) => {
  const state = getState()
  return dispatch({ type: ADD_EVENT, venueId, isMultiInventoryTypeEnabled: state.featureFlags.isMultiInventoryTypeEnabled })
}

export const deleteEventConfirm = id => ({ type: DELETE_EVENT_CONFIRM, id })

export const deleteEvent = id => (dispatch, getState) => {
  const state = getState()
  const currentEvent = _.find(state.eventListData.toJS(), { id })
  return !currentEvent
    ? dispatch(loadEvent(null))
    : requestDeleteEvent(currentEvent).then(response => {
        // eslint-disable-next-line no-unused-expressions
        response.error ? dispatch(loadEvents(venueId)) : dispatch(postDeleteEvent(id))
      })
}

export const showRecurs = id => ({ type: TOGGLE_RECURS, id })

export const toggleRecurrence = (id, e) => {
  const field = 'is_recurring'
  const value = e.target.checked
  return { type: UPDATE_EVENT, id, field, value }
}

export const changeRecurrenceFrequency = freq => {
  const frequency = freq.target.value
  return { type: CHANGE_RECURRENCE_FREQUENCY, frequency }
}

// Preview pattern does not work in ie9
export const uploadPhotoPreview = (id, uploads, photoId) => {
  const url = uploads[0].preview
  return { type: UPLOAD_PHOTO_PREVIEW, id, url, photoId }
}

export const uploadPhoto = (id, accepted, photoId) => (dispatch, getState) =>
  uploadPhotoRequest(accepted[0]).then(uploadData => {
    dispatch(uploadPhotoSuccess(id, photoId, uploadData))
    dispatch(loadCropEdit(id, photoId))
  })

export const updatePhotoTitle = (id, photoId, e) => {
  const { value } = e.target
  return { type: UPDATE_PHOTO_TITLE, id, photoId, value }
}

export const deletePhoto = (id, photoId) => ({ type: DELETE_PHOTO, id, photoId })

export const updateEvent = (id, e) => {
  const field = e.target.name
  const { value } = e.target
  return { type: UPDATE_EVENT, id, field, value }
}

export const changeBookingPolicyType = (id, e) => (dispatch, getState) => {
  const defaultBookingPolicy = getState().venueConfig.default_booking_policy
  dispatch(changeBookingPolicyTypeWithDefault(id, e, defaultBookingPolicy))
}

const changeBookingPolicyTypeWithDefault = (id, e, defaultBookingPolicy) => {
  const field = e.target.name
  const { value } = e.target
  return {
    type: CHANGE_BOOKING_POLICY_TYPE,
    id,
    field,
    value,
    defaultBookingPolicy,
  }
}

export const changeBookingPolicy = (id, e) => {
  const field = e.target.name
  const { value } = e.target
  return { type: CHANGE_BOOKING_POLICY, id, field, value }
}

export const changeCancellationPolicyType = (id, e) => (dispatch, getState) => {
  const defaultCancellationPolicy = getState().venueConfig.default_cancellation_policy
  dispatch(changeCancellationPolicyTypeWithDefault(id, e, defaultCancellationPolicy))
}

const changeCancellationPolicyTypeWithDefault = (id, e, defaultCancellationPolicy) => {
  const field = e.target.name
  const { value } = e.target
  return {
    type: CHANGE_CANCELLATION_POLICY_TYPE,
    id,
    field,
    value,
    defaultCancellationPolicy,
  }
}

export const updateEditingEventStartDate = isEditing => ({ type: UPDATE_EDITING_EVENT_START_DATE, isEditing })

export const changeCancellationPolicy = (id, e) => {
  const field = e.target.name
  const { value } = e.target
  return { type: CHANGE_CANCELLATION_POLICY, id, field, value }
}

export const updateDays = (id, e) => {
  const day = e.target.name
  const on = e.target.checked
  return { type: UPDATE_DAYS, id, day, on }
}

export const updateDescription = (id, html) => {
  const field = 'description'
  const value = html
  return { type: UPDATE_EVENT, id, field, value }
}

export const updateLinkedExperience = (id, experienceId) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_EVENT,
    id,
    field: 'experience_id',
    value: experienceId,
  })

export const loadCropper = (id, uploads, photoId) => {
  const url = uploads[0].preview
  return { type: CROPPING, url, id, photoId }
}

export const loadEvents = venueId => (dispatch, getState) => {
  dispatch(setLoadingState(true))
  return requestEvents(venueId).then(eventData => {
    dispatch(loadEventsSuccess(eventData, venueId))
    dispatch(setLoadingState(false))
    batchDispatchEventSalesRequests(dispatch, venueId, eventData.events)
  })
}

export const loadInitialData = (venueId, selectedEventId) => (dispatch, getState) => {
  dispatch(setLoadingState(true))
  return Promise.all([requestEvents(venueId), requestSeatingAreas(venueId), getFeatureFlagsProvider()]).then(
    ([eventData, seating_areas, featureFlagsProvider]) => {
      dispatch(loadFeatureFlags(featureFlagsProvider))
      dispatch(loadVenueConfig(eventData))
      dispatch(setSeatingAreas(seating_areas))
      dispatch(loadEventsSuccess(eventData, venueId))
      dispatch(setLoadingState(false))
      batchDispatchEventSalesRequests(dispatch, venueId, eventData.events)
      if (selectedEventId) {
        dispatch(loadEvent(selectedEventId))
      }
    }
  )
}

const SALES_CHUNK_PARALLEL_REQUESTS = 4 // 4 parallel requests
const SALES_CHUNK_SIZE = 10 // 10 per request

const batchDispatchEventSalesRequests = (dispatch, venueId, events) => {
  if (_.isEmpty(events)) {
    return
  }
  const parallelEventsChunks = _.chunk(events, SALES_CHUNK_PARALLEL_REQUESTS * SALES_CHUNK_SIZE)
  let promiseWhile = Promise.resolve(null)
  do {
    const parallelEvents = parallelEventsChunks.shift()
    promiseWhile = promiseWhile.then(() =>
      Promise.all(
        _.chunk(parallelEvents, SALES_CHUNK_SIZE).map(chunkEvents =>
          loadEventSales(
            dispatch,
            venueId,
            chunkEvents.map(e => e.id)
          )
        )
      )
    )
  } while (parallelEventsChunks.length > 0)
  // eslint-disable-next-line consistent-return
  return promiseWhile
}

export const loadEventsSuccess = (eventData, venueId) => (dispatch, getState) =>
  dispatch({
    type: LOAD_EVENTS_SUCCESS,
    events: eventData.events,
    upsells: eventData.upsells,
    booking_policies: getState().venueConfig.booking_policies,
    cancellation_policies: getState().venueConfig.cancellation_policies,
    venueId,
  })

export const loadVenueConfig = eventData => ({
  type: LOAD_VENUE_CONFIG,
  default_booking_policy: eventData.default_booking_policy,
  default_cancellation_policy: eventData.default_cancellation_policy,
  min_price_enabled: eventData.min_price_enabled,
  tag_groups: eventData.tag_groups,
  booking_policies: eventData.booking_policies,
  cancellation_policies: eventData.cancellation_policies,
  venue_interactive_floorplan_images: eventData.venue_interactive_floorplan_images,
})

export const loadEventSales = (dispatch, venueId, eventIds) =>
  requestEventSales(venueId, eventIds).then(eventSales => {
    dispatch(loadEventSalesSuccess(eventSales))
  })

export const loadEventInventories = (dispatch, venueId, eventIds) =>
  requestEventInventories(venueId, eventIds).then(eventInventories => {
    dispatch(loadEventInventoriesSuccess(eventInventories))
    return eventInventories
  })

export const loadEventSalesSuccess = eventSales => ({
  type: LOAD_EVENT_SALES_SUCCESS,
  eventSales,
})

export const loadEventInventoriesSuccess = eventInventories => ({
  type: LOAD_EVENT_INVENTORIES_SUCCESS,
  eventInventories,
})

export const setLoadingState = stateVal => ({ type: IS_LOADING_STATE, stateVal })

export const setSeatingAreas = seatingAreas => ({ type: LOAD_SEATING_AREAS_SUCCESS, seatingAreas })

export const uploadPhotoSuccess = (id, photoId, actions) => ({ type: UPLOAD_PHOTO_SUCCESS, id, photoId, actions })

export const loadCropEdit = (id, photoId) => ({ type: CROPPING, id, photoId })

export const toggleHidden = (id, value) => {
  const field = 'hide_event'
  return { type: UPDATE_EVENT, id, field, value }
}

export const togglePrivate = id => {
  const field = 'private_event'
  return { type: UPDATE_EVENT, id, field }
}

export const toggle3dFloorplan = () => ({ type: TOGGLE_3D_FLOORPLAN })

export const toggleMultiInventory = () => ({ type: TOGGLE_MULTI_INVENTORY })

export const refreshEventImages = (id, value) => {
  const field = 'photo_map'
  return { type: LOAD_EVENT_IMAGE_DATA, id, field, value }
}

export const refreshEventDescription = (id, value) => {
  const field = 'description'
  return { type: LOAD_EVENT_DESCRIPTION_DATA, id, field, value }
}

// eslint-disable-next-line consistent-return
export const refreshImagesAndDescription = (dispatch, venue_id, event_id) => {
  if (event_id) {
    return requestEvent(venue_id, event_id).then(event => {
      dispatch(refreshEventImages(event_id, event.photo_map))
      dispatch(refreshEventDescription(event_id, event.description))
    })
  }
}

export const cropImage = (id, photoId, coords) => ({ type: CROP_PHOTO, id, photoId, coords })

export const closeCrop = () => ({ type: CROPPING, cropping: false, photoId: null })

export const saveCrop = (eventId, photoId) => ({ type: CROPPING_SAVE, cropping: false, photoId })
